<template>
  <div class="merch-card">
    <!-- 搜索列 -->
    <el-form
      ref="queryParams"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleCurrentChange"
    >
      <el-form-item label="账单编号：">
        <el-input
          v-model="queryParams.statementNo"
          placeholder="请输入账单编号"
          clearable
        />
      </el-form-item>
      <el-form-item label="供应商：">
        <el-input
          v-model="queryParams.supplier"
          placeholder="请输入供应商名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="状态：">
        <el-select
          clearable
          v-model="queryParams.status"
          placeholder="请选择状态"
        >
          <el-option label="待对账" value="0"></el-option>
          <el-option label="正常" value="1"></el-option>
          <!-- <el-option label="冻结" value="2"></el-option> -->
          <el-option label="已分账" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="minItem" label="结算日期：">
        <el-date-picker
          v-model="queryParams.dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleCurrentChange"
          style="width: 80px"
          >查 询</el-button
        >
        <el-button @click="handleReset" style="width: 80px" plain
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table
      :data="searchList"
      v-loading="loading"
      border
      style="width: 100%"
      max-height="520px"
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column
        align="center"
        label="序号"
        type="index"
        :index="indexMethod"
        width="70"
      />
      <el-table-column prop="statementNo" label="结算账单号" align="center" />
      <el-table-column prop="supplierName" label="供应商名称" align="center" />
      <el-table-column
        prop="statementOrderNumber"
        label="结算订单数"
        align="center"
      />
      <el-table-column prop="statementAmount" label="结算总额" align="center" />
      <el-table-column
        prop="statementTime"
        label="结算日期"
        align="center"
        width="110"
      />
      <el-table-column
        prop="acquiringWithdrawalAmount"
        label="收单提现总额"
        align="center"
      />
      <el-table-column
        prop="acquiringWithdrawalTime"
        label="收单提现时间"
        align="center"
        width="110"
      />
      <el-table-column
        prop="acquiringWithdrawalChannel"
        label="收单提现渠道"
        align="center"
      />
      <el-table-column
        prop="acquiringWithdrawalStatus"
        label="收单提现状态"
        align="center"
      />
      <el-table-column
        prop="xwReceivedAccountNo"
        label="新网到账编号"
        align="center"
      />
      <el-table-column
        prop="xwReceivedAccountAmount"
        label="新网到账总额"
        align="center"
      >
        <template slot-scope="{ row }">
          {{ row.xwReceivedAccountAmount || "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="xwReceivedAccountTime"
        label="新网到账时间"
        align="center"
        width="110"
      >
        <template slot-scope="{ row }">
          {{ row.xwReceivedAccountTime || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="{ row }">
          {{ row.status | statusFilter }}
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center" />
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="{ row }">
          <div>
            <span class="btsed" @click="viewDetails(row)">订单明细</span>
            <span
              class="btsed"
              @click="Manualthawing(row)"
              v-if="row.status == 0"
              >手动对账</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
    <!-- 确认对账 -->
    <el-dialog
      title="确认对账"
      :visible.sync="dialogpaytinfor"
      :close-on-click-modal="false"
      width="550px"
    >
      <div style="margin-bottom: 20px">
        <span class="el-icon-warning"></span
        >解冻后次日将新网结算，解冻后无法恢复。
      </div>
      <el-form
        ref="refForm"
        :model="refItem"
        :rules="rules"
        label-width="130px"
      >
        <el-form-item label="到账总额：" prop="xwReceivedAccountAmount">
          <el-input
            v-model="refItem.xwReceivedAccountAmount"
            placeholder="请输入到账总额"
            clearable
            disabled
          />
        </el-form-item>
        <el-form-item label="到账日期：" prop="receiptTime">
          <el-date-picker
            v-model="refItem.receiptTime"
            type="date"
            placeholder="选择到账日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            clearable
            style="width: 380px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="新网到账编号：" prop="receivedAccountNo">
          <el-input
            v-model="refItem.receivedAccountNo"
            placeholder="请输入新网到账编号（见企业微信推送）"
            clearable
          />
        </el-form-item>
        <el-form-item label="请输入手动对账备注：" prop="remark">
          <el-input
            v-model="refItem.remark"
            :autosize="{ minRows: 4, maxRows: 7 }"
            maxlength="50"
            type="textarea"
            show-word-limit
            placeholder="请输入解冻备注原因"
            clearable
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="text-right">
        <el-button @click="dialogpaytinfor = false">取 消</el-button>
        <el-button type="primary" @click="unfreeds" :loading="loading">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  statementList, //三方对账-列表
  unfreeze, //三方对账-手动解冻
} from "@/api/apiAll/phpUrl";
export default {
  name: "",
  data() {
    return {
      loading: false,
      total: 0,
      //列表搜索
      queryParams: {
        page: 1,
        perPage: 10,
        dateTime: [],
        settlementStartTime: "",
        settlementEndTime: "",
        statementNo: "", //账单编号
        supplier: "", //供应商名字
        status: "", //账单状态 0-待对账 1-正常 2-冻结 3-已分账
      },
      searchList: [], //账单列表
      dialogpaytinfor: false, // 手动解冻弹窗
      refItem: {
        id: "",
        xwReceivedAccountAmount: "", //到账总额
        receiptTime: "", //到账时间
        receivedAccountNo: "", //新网到账编号
        remark: "", //备注
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
      rules: {
        xwReceivedAccountAmount: [
          { required: true, message: "请输入到账总额", trigger: "blur" },
        ],
        receiptTime: [
          { required: true, message: "请选择到账时间", trigger: "change" },
        ],
        receivedAccountNo: [
          { required: true, message: "请输入新网到账编号", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入手动对账备注", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getList();
  },
  filters: {
    statusFilter(v) {
      switch (v) {
        case 0:
          return "待对账";
        case 1:
          return "正常";
        case 2:
          return "冻结";
        case 3:
          return "已分账";
      }
    },
  },
  methods: {
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    async getList() {
      if (this.queryParams.dateTime && this.queryParams.dateTime.length > 0) {
        this.queryParams.settlementStartTime = this.queryParams.dateTime[0];
        this.queryParams.settlementEndTime = this.queryParams.dateTime[1];
      } else {
        this.queryParams.settlementStartTime = "";
        this.queryParams.settlementEndTime = "";
      }
      this.loading = true;
      statementList(this.queryParams).then((res) => {
        this.loading = false;
        this.searchList = res.data.data;
        this.total = res.data.total;
      });
      this.setquery();
    },
    //搜索
    handleCurrentChange() {
      this.queryParams.page = 1;
      this.getList();
    },
    //重置
    handleReset() {
      this.dateTime = [];
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
    },
    /** 订单明细 */
    viewDetails(row) {
      var data = {
        settlementBillNumber: row.statementNo,
      };
      this.$router.push({
        name: "采购/销售对账单",
        params: {
          data,
        },
      });
    },
    /** 手动解冻 */
    Manualthawing(row) {
      Object.assign(this.refItem, this.$options.data().refItem);
      this.refItem.id = row.id;
      this.refItem.xwReceivedAccountAmount = row.acquiringWithdrawalAmount;
      this.dialogpaytinfor = true;
    },
    /** 确认对账 */
    unfreeds() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          unfreeze(this.refItem).then((res) => {
            this.loading = false;
            if (res.code == 200) {
              this.$message.success("解冻成功");
              this.dialogpaytinfor = false;
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
